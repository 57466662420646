import React, { Component } from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import uuid from'react-uuid';
import Modal from 'react-bootstrap/Modal';
import OrderModifyForm from "./orderModifyForm";
import { withTranslation } from 'react-i18next';

class OrderCell extends Component {
    state = 
    {
        showModifyModal: false, // Set the initial state to show the modal
    };

    handleClick = () => {
        const {
            type,
            data,
            onOrderClicked
        } = this.props;

      onOrderClicked(type, data);
    };

    handleModifyModalClose = () => {
        this.setState({ showModifyModal: false });
      };

    handleModify = (e, data) => {
        this.setState({showModifyModal: true})
    };

    modifyOrder = (orderData) => {
        console.log(orderData);
        this.props.modifyOrder(orderData);
    }


    handleWithdraw = (e, data) => {
        this.props.withdrawOrder(data.orderData);
    };

    handlePick = (e, data) => {
        this.props.pickOrder(data.orderData);
    };

    render() {         

        //const {t, allowEdit, isNew} = this.props;

        const {
            t,
            value,
            type,
            isDepth,
            pricePrecision,
            volumePrecision,
            uomName,
            currencyName,
            currencySymbol,
            isOrganizationOrder,
            isUserOrder,
            align,
            data,
            price,
            volume,
            instrumentId,
            orderId,
            showContextMenu
        } = this.props; 

        const {
            marketName,
            priceBasisName,
            termName,
            qualityAttributeValues
        } = data;

        const {
            showModifyModal
        } = this.state;

        //Show the price if populated, bid/offer if not, and nothing if neither of those are popualted.
        const cellValue = value && Number(value).toFixed(pricePrecision)
        const backgroundColor = type === "Bid" ? "0,0,255" : type === "Offer" ? "255,0,0" : "none";
        const backgroundOpacity = isDepth ? ".15" : ".35";
        const background = "rgba("+backgroundColor+","+backgroundOpacity+")";
        const color = isOrganizationOrder ? "#4293A4" : "white";
        const contextMenuId = uuid();
        const orderData = 
            {
                instrumentId: instrumentId,
                orderId: orderId,
                orderDirection: type === "Bid" ? "Buy" : type === "Offer" ? "Sell" : undefined,
                price:  price,
                volume:  volume,
            }
        
        return (
            <div>
                <ContextMenuTrigger id={contextMenuId}>
                <div className="clickable" onClick={this.handleClick} style={{background: background, color: color, paddingLeft:"10px", paddingRight: "10px", fontWeight: isDepth ? "normal" : "bold", textAlign: !align ? "center" : (type === "Bid" ? "right" : "left"), width:"100%", height:"100%"}}>

                    {/* <div onClick={this.handleOpen} className="mark-cell clickable"> */}
                    {
                        type === "Bid" &&
                        <span style={{fontSize: 9, verticalAlign: 'top'}}>
                            {this.props.superscript}
                        </span>
                    }
                    <span>
                        {cellValue}
                    </span>    
                    {
                        type === "Offer" &&
                        <span style={{fontSize: 9, verticalAlign: 'top'}}>
                            {this.props.superscript}
                        </span>
                    }
                    
                </div>
                </ContextMenuTrigger>
                {showContextMenu &&
                    <ContextMenu id={contextMenuId}>
                        <div style={{background: "ghostwhite", fontWeight:"normal", fontSize:"14px", color:"black", padding:"5px 20px 5px 20px", borderRadius:"10px", cursor:"default"}}>
                        { isUserOrder &&
                            <MenuItem data={{orderData: orderData}} onClick={this.handleModify}>
                                <div style={{paddingTop:"5px", paddingBottom:"5px"}}>Modify</div>
                            </MenuItem>
                        }
                        { isUserOrder &&
                            <MenuItem data={{orderData: orderData}} onClick={this.handleWithdraw}>
                                <div style={{paddingTop:"5px", paddingBottom:"5px"}}>Withdraw</div>
                            </MenuItem>
                        }
                        {/* <MenuItem divider /> */}
                       {/*  { !isOrganizationOrder && */}
                        <MenuItem data={{orderData: orderData}} onClick={this.handlePick}>
                            <div style={{paddingTop:"5px", paddingBottom:"5px"}}>Pick</div>
                        </MenuItem>
{/*                         } */}
                        </div>
                    </ContextMenu>
                }
                <div>
                    <Modal show={showModifyModal} onHide={this.handleModifyModalClose}
                        //centered
                            size="md"
                            backdrop={false}
                            animation={false}
                            keyboard={false}>
                        <Modal.Header /* className="bg-dark text-light"  data-bs-theme="dark" */ closeButton>
                            <Modal.Title>
                                {t("ModifyOrder")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body /* className="bg-dark text-light" */ >
                            <OrderModifyForm 
                                orderData={orderData} 
                                modifyOrder={this.modifyOrder} 
                                handleModalClose={this.handleModifyModalClose}
                                pricePrecision={pricePrecision}
                                volumePrecision={volumePrecision}
                                currencyName={currencyName}
                                currencySymbol={currencySymbol}
                                uomName={uomName} 
                                marketName={marketName} 
                                priceBasisName={priceBasisName} 
                                termName={termName} 
                                qualityAttributeValues={qualityAttributeValues} 
                                />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}
 
export default withTranslation(["marketdata"])(OrderCell);