import React, { Component } from 'react';
import Table from '../../common/components/table/table';
import OrderCell from "../common/orderCell";
import QualityAttributeBadges from "../common/qualityAttributeBadges";
import { withTranslation } from 'react-i18next';
import _ from "lodash";

class MarketGrid extends Component {
    state = {
        gridData: [],
      };

    columns =[
        //{path: 'instrumentId', label: this.props.t("InstrumentId")},
        {path: 'marketNameDisplay', label: this.props.t("MarketName")},
        {path: 'termNameDisplay', label: this.props.t("TermName")},
        {path: 'qualityAttributeValues', label: this.props.t("QualityAttributes"), content: data => <QualityAttributeBadges qualityAttributeValues={data.qualityAttributeValuesDisplay} />  },
        {path: 'expandDepth', label: "", content: data => <span> {data.queueOrder===0 && data.hasDepth && <span><input type="checkbox" class="queue-toggle" id={data.instrumentId} hidden/><label for={data.instrumentId}>+</label></span>} </span> },
        //{path: 'runningTotalBidVolume', label: this.props.t("RunningTotalBidVolume")},
        {path: 'bidVolume', label: this.props.t("BidVolume"),  content: data => <OrderCell       
            value={data.bidVolume}
            type={"Bid"}
            isOrganizationOrder={data.isOrganizationBid}
            isUserOrder={data.isUserBid}
            isDepth={data.queueOrder>0}
            pricePrecision={data.pricePrecision}
            volumePrecision={data.volumePrecision}
            currencyName={data.currencyName}
            currencySymbol={data.currencySymbol}
            uomName={data.uomName}
            data={data}   
            price={data.bidPrice}
            volume={data.bidVolume}
            instrumentId={data.instrumentId}         
            orderId={data.bidOrderId}
            onOrderClicked={this.props.onOrderClicked}
            showContextMenu={true}
            modifyOrder={this.props.modifyOrder}
            withdrawOrder={this.props.withdrawOrder}
            pickOrder={this.props.pickOrder}
            align={true}
            superscript={data.bidAllOrNone && "aon"}
        />},        
        {path: 'bidPrice', label: this.props.t("BidPrice"),  content: data => <OrderCell       
            value={data.bidPrice}
            type={"Bid"}
            isOrganizationOrder={data.isOrganizationBid}
            isUserOrder={data.isUserBid}
            isDepth={data.queueOrder>0}
            pricePrecision={data.pricePrecision}
            volumePrecision={data.volumePrecision}
            currencyName={data.currencyName}
            currencySymbol={data.currencySymbol}
            uomName={data.uomName}
            data={data}
            price={data.bidPrice}
            volume={data.bidVolume}
            instrumentId={data.instrumentId}
            orderId={data.bidOrderId}
            onOrderClicked={this.props.onOrderClicked}
            showContextMenu={true}
            modifyOrder={this.props.modifyOrder}
            withdrawOrder={this.props.withdrawOrder}
            pickOrder={this.props.pickOrder}            
            align={true}
        />},
        {path: 'offerPrice', label: this.props.t("OfferPrice"),  content: data => <OrderCell       
            value={data.offerPrice}
            type={"Offer"}
            isOrganizationOrder={data.isOrganizationOffer}
            isUserOrder={data.isUserOffer}
            isDepth={data.queueOrder>0}
            pricePrecision={data.pricePrecision}
            volumePrecision={data.volumePrecision}
            currencyName={data.currencyName}
            currencySymbol={data.currencySymbol}
            uomName={data.uomName}
            data={data}
            price={data.offerPrice}
            volume={data.offerVolume}
            instrumentId={data.instrumentId}
            orderId={data.offerOrderId}
            onOrderClicked={this.props.onOrderClicked}
            showContextMenu={true}
            modifyOrder={this.props.modifyOrder}
            withdrawOrder={this.props.withdrawOrder}
            pickOrder={this.props.pickOrder}            
            align={true}
        />},
        {path: 'offerVolume', label: this.props.t("OfferVolume"),  content: data => <OrderCell       
            value={data.offerVolume}
            isOrganizationOrder={data.isOrganizationOffer}
            isUserOrder={data.isUserOffer}
            type={"Offer"}
            isDepth={data.queueOrder>0}
            pricePrecision={data.pricePrecision}
            volumePrecision={data.volumePrecision}
            currencyName={data.currencyName}
            currencySymbol={data.currencySymbol}
            uomName={data.uomName}
            data={data}
            price={data.offerPrice}
            volume={data.offerVolume}
            instrumentId={data.instrumentId}
            orderId={data.offerOrderId}
            onOrderClicked={this.props.onOrderClicked}
            showContextMenu={true}
            modifyOrder={this.props.modifyOrder}
            withdrawOrder={this.props.withdrawOrder}
            pickOrder={this.props.pickOrder}            
            align={true}
            superscript={data.offerAllOrNone && "aon"}
        />},     
        //{path: 'runningTotalOfferVolume', label: this.props.t("RunningTotalOfferVolume")},
        //{path: 'queueOrder', label: this.props.t("QueueOrder")},
    ];

    async componentDidMount()
    {
        this.prepareMarketGrid();
    };

    async componentDidUpdate(previousProps) {
        if (
          !_.isEqual(previousProps.marketOrders, this.props.marketOrders) ||
          !_.isEqual(previousProps.organizationOrders, this.props.organizationOrders)
        ) {
            this.prepareMarketGrid();
        }
    }

    prepareMarketGrid ()
    {
        const {marketOrders, organizationOrders, userAccountId} = this.props;

        // Group orders by instrumentId
        const groupedOrders = _.groupBy(marketOrders, 'instrumentId');

        // Process each instrumentId
        const gridData = _.flatMap(groupedOrders, (orders) => {
            // Separate Buy and Sell orders
            const buyOrders = orders.filter(order => order.orderDirection === 'Buy');
            const sellOrders = orders.filter(order => order.orderDirection === 'Sell');

            // Sort Buy orders by price (highest to lowest) and updateDate (oldest to newest)
            const sortedBids = _.orderBy(buyOrders, ['price', 'orderDate'], ['desc', 'asc']);

            // Sort Sell orders by price (lowest to highest) and updateDate (oldest to newest)
            const sortedOffers = _.orderBy(sellOrders, ['price', 'orderDate'], ['asc', 'asc']);

            // Pair Buy and Sell orders
            const pairings = _.zip(sortedBids, sortedOffers).map(pair => ({
                bid: pair[0],
                offer: pair[1],
            }));

            let runningTotalBidVolume = 0;
            let runningTotalOfferVolume = 0;

            // Flatten out the pairings
            return pairings.map((pairing, index) => {

                // Increment running totals
                runningTotalBidVolume += pairing.bid ? pairing.bid.volume : 0;
                runningTotalOfferVolume += pairing.offer ? pairing.offer.volume : 0;

                const organizationBidIndex = organizationOrders.findIndex(x => x.orderId === (pairing.bid ? pairing.bid.orderId : undefined));
                const isOrganizationBid = organizationBidIndex >=0;
                const isUserBid = isOrganizationBid ? organizationOrders[organizationBidIndex].userAccountId === userAccountId : false;
                const organizationOfferIndex = organizationOrders.findIndex(x => x.orderId === (pairing.offer ? pairing.offer.orderId: undefined));
                const isOrganizationOffer = organizationOfferIndex >=0;
                const isUserOffer = isOrganizationOffer ? organizationOrders[organizationOfferIndex].userAccountId === userAccountId : false;

                return {
                instrumentId: pairing.bid ? pairing.bid.instrumentId : pairing.offer.instrumentId,
                termId: pairing.bid ? pairing.bid.termId : pairing.offer.termId,
                termName: pairing.bid ? pairing.bid.termName : pairing.offer.termName,
                termNameDisplay: index === 0 ? pairing.bid ? pairing.bid.termName : pairing.offer.termName : undefined,
                marketName: pairing.bid ? pairing.bid.marketName : pairing.offer.marketName,
                marketNameDisplay: index === 0 ? pairing.bid ? pairing.bid.marketName : pairing.offer.marketName : undefined,
                marketId: pairing.bid ? pairing.bid.marketId : pairing.offer.marketId,
                uomId: pairing.bid ? pairing.bid.uomId : pairing.offer.uomId,
                uomName: pairing.bid ? pairing.bid.uomName : pairing.offer.uomName,
                currencyId: pairing.bid ? pairing.bid.currencyId : pairing.offer.currencyId,
                currencyName: pairing.bid ? pairing.bid.currencyName : pairing.offer.currencyName,
                currencySymbol: (pairing.bid ? pairing.bid.currencySymbol : pairing.offer.currencySymbol) ?? "$",
                volumePrecision: (pairing.bid ? pairing.bid.volumePrecision : pairing.offer.volumePrecision) ?? 0,
                pricePrecision: (pairing.bid ? pairing.bid.pricePrecision : pairing.offer.pricePrecision) ?? 2,
                priceBasisId: pairing.bid ? pairing.bid.priceBasisId : pairing.offer.priceBasisId,
                priceBasisName: pairing.bid ? pairing.bid.priceBasisName : pairing.offer.priceBasisName,
                qualityAttributeValues: pairing.bid ? pairing.bid.qualityAttributeValues : pairing.offer.qualityAttributeValues,
                qualityAttributeValuesDisplay: index === 0 ? pairing.bid ? pairing.bid.qualityAttributeValues : pairing.offer.qualityAttributeValues : undefined,
                bidOrderId: pairing.bid ? pairing.bid.orderId : undefined,
                bidPrice: pairing.bid ? pairing.bid.price : undefined,
                bidVolume: pairing.bid ? pairing.bid.volume : undefined,
                bidAllOrNone: pairing.bid ? pairing.bid.allOrNone : undefined,
                runningTotalBidVolume: runningTotalBidVolume === 0 ? undefined : runningTotalBidVolume,
                isOrganizationBid: isOrganizationBid,
                isUserBid: isUserBid,
                bidOrderDate: pairing.bid ? pairing.bid.orderDate : undefined,
                offerOrderId: pairing.offer ? pairing.offer.orderId : undefined,
                offerPrice: pairing.offer ? pairing.offer.price : undefined,
                offerVolume: pairing.offer ? pairing.offer.volume : undefined,
                offerAllOrNone: pairing.offer ? pairing.offer.allOrNone : undefined,
                runningTotalOfferVolume: runningTotalOfferVolume === 0 ? undefined : runningTotalOfferVolume,
                isOrganizationOffer: isOrganizationOffer,
                isUserOffer: isUserOffer,
                offerOrderDate: pairing.offer ? pairing.offer.orderDate : undefined,
                queueOrder: index, // Queue order
                class: index > 0 ? "row-collapse" : undefined,
                rowId: (pairing.bid ? pairing.bid.instrumentId : pairing.offer.instrumentId)+"|"+index,
                hasDepth: pairings.filter(x => (x.bid ? x.bid.instrumentId : x.offer.instrumentId) === (pairing.bid ? pairing.bid.instrumentId : pairing.offer.instrumentId)).length > 1
                };
            });

            //return flattenedPairings;
        })
        .sort((a, b) => {
            // Sort by instrumentId first, then by queue order
            if (a.instrumentId !== b.instrumentId) {
              return a.instrumentId - b.instrumentId;
            } else {
              return a.queueOrder - b.queueOrder;
            }
          });

        this.setState({gridData: gridData});
    };

    render() { 
        const { gridData } = this.state;

        const sortColumn = "orderId"
        return (
            <Table className="table table-dark table-sm market-grid" groupingProperty={"instrumentId"} classProperty={"class"} columns= {this.columns} sortColumn = {sortColumn} data={gridData} valueProperty="rowId"/>
        );
    }
}

export default withTranslation(["trading"])(MarketGrid);